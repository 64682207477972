import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@giphy/react-components'
import PropTypes from 'prop-types'
import useGiphy from '../hooks/useGiphy'
import { Box, InputGroup, InputRightElement, Input } from '@chakra-ui/react'
import { CheckCircleRounded } from '../../../atoms/Icon'

const giphyContainerStyles = {
	'w': 'full',
	'& picture:hover': {
		cursor: 'pointer',
	},
}

const giphyOverlayStyles = {
	pos: 'absolute',
	left: 0,
	top: 0,
	right: 0,
	bottom: 0,
	background: 'rgba(0, 0, 0, 0.4)',
	display: 'flex',
	color: 'white',
	justifyContent: 'center',
	alignItems: 'center',
}

const Adornment = () => (
	<a style={{ marginTop: '10px' }} href="https://giphy.com/" target="_blank" alt="Powered by GIPHY" rel="noreferrer">
		<svg width={103} height={28} xmlns="http://www.w3.org/2000/svg">
			<g fill="none" fillRule="evenodd">
				<text
					transform="translate(26,-2)"
					fill="#818181"
					fontFamily="Helvetica-Bold, Helvetica"
					fontSize={11.1}
					fontWeight="bold">
					<tspan x={0} y={11}>
						{'POWERED BY'}
					</tspan>
				</text>
				<g fillRule="nonzero">
					<path fill="#0F9" d="M0 4h3v22H0z" />
					<path fill="#93F" d="M18 9h3v17h-3z" />
					<path fill="#0CF" d="M0 25h21v3H0z" />
					<path fill="#FFF35C" d="M0 1h12v3H0z" />
					<path fill="#F66" d="M18 7V4h-3V1h-3v9h9V7" />
					<path d="M18 13v-3h3m-9-9v3H9" fill="#000" />
				</g>
				<path
					d="M39.037 15.317c-1.22-1.158-2.683-1.463-3.78-1.463-2.684 0-4.452 1.585-4.452 4.878 0 2.134 1.097 4.756 4.451 4.756.854 0 2.256-.183 3.17-.854V20.5H34.22v-3.659h8.11v7.379c-1.037 2.134-3.903 3.231-7.135 3.231-6.524 0-9.024-4.39-9.024-8.72 0-4.329 2.866-8.78 9.085-8.78 2.317 0 4.33.488 6.524 2.683l-2.743 2.683Zm5.548 11.707V10.44h4.635v16.585h-4.635v-.001Zm12.256-4.512v4.451h-4.695V10.44h8.05c4.45 0 6.645 2.805 6.645 6.037 0 3.414-2.195 6.036-6.646 6.036h-3.354v-.001Zm0-3.963h3.354c1.28 0 1.951-.976 1.951-2.012 0-1.098-.67-2.074-1.95-2.074H56.84v4.086h.001Zm22.379 8.475v-6.28h-5.976v6.28h-4.695V10.44h4.695v6.28h5.976v-6.28h4.634v16.585H79.22v-.001ZM93.976 16.11l3.597-5.671h5.305v.183l-6.585 9.756v6.585h-4.695v-6.585l-6.28-9.756v-.183h5.304l3.354 5.67v.001Z"
					fill="#000"
					fillRule="nonzero"
				/>
			</g>
		</svg>
	</a>
)

const GiphySearch = ({ onGifClick, defaultSearch, limit, selectedUrls }) => {
	const giphyGridRef = useRef()
	useEffect(() => {
		if (giphyGridRef?.current) {
			setWidth(giphyGridRef?.current?.offsetWidth)
		}
	}, [giphyGridRef])

	const [width, setWidth] = useState(320)
	const [search, setSearch] = useState(defaultSearch)
	const [query, setQuery] = useState(defaultSearch)

	useEffect(() => {
		var term = query != '' ? query : defaultSearch
		const typeTimer = setTimeout(() => {
			setSearch(term)
		}, 1200)
		return () => clearTimeout(typeTimer)
	}, [query])

	// configure your fetch: fetch 10 gifs at a time as the user scrolls (offset is handled by the grid)
	const fetchGifs = (offset) => {
		return useGiphy({ offset, limit, search }).then((result) => result.data)
	}

	// Overlay component gets GifOverlayProps
	const Overlay = ({ gif }) => {
		if (selectedUrls.includes(gif.images.looping.mp4)) {
			return (
				<Box sx={giphyOverlayStyles}>
					<CheckCircleRounded fontSize="large" />
				</Box>
			)
		} else {
			return null
		}
	}

	// Render the React Component and pass it your fetchGifs as a prop
	return (
		<Box sx={giphyContainerStyles} ref={giphyGridRef}>
			<InputGroup mb="1rem">
				<Input
					size="lg"
					placeholder="Search GIPHY"
					name="search"
					onChange={(e) => setQuery(e.target.value)}
					defaultValue={search}
				/>
				<InputRightElement children={<Adornment />} w="125px" />
			</InputGroup>

			<div key={search}>
				<Grid
					noLink={true}
					onGifClick={onGifClick}
					fetchGifs={fetchGifs}
					noResultsMessage={'There were no Giphy matching that search.'}
					hideAttribution={true}
					width={width}
					columns={width > 414 ? 3 : 2}
					gutter={6}
					ref={giphyGridRef}
					overlay={Overlay}
				/>
			</div>
		</Box>
	)
}

/* **************************************
DEFAULT PROPS VALUE
************************************** */

GiphySearch.defaultProps = {
	limit: 16,
	onGifClick: null,
	defaultSearch: 'celebrate',
	selectedUrls: [],
}

/* **************************************
COMPONENT PROPS TYPES
************************************** */

GiphySearch.propTypes = {
	limit: PropTypes.number,
	onGifClick: PropTypes.func.isRequired,
	defaultSearch: PropTypes.string,
	selectedUrls: PropTypes.array,
}
export default GiphySearch
